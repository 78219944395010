@import './shared/styles/_vuetify-overrides.scss';




























































































































































































































































































































































































































































































































































































































































































.custom-header {
	transition: background-color 0.3s ease-in-out;
}
.custom-header.v-expansion-panel-header--active {
	background-color: #E0E0E0;
}
.custom-header:hover {
	background-color: #EEEEEE;
}
.heading-scan-name {
	width: 300px;
}
.heading-texts {
	display: flex;
}
